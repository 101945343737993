<template>
    <van-popup v-model:show="show" position="bottom" :style="{background: '#EEE'}" closeable round @close="onClose">
        <div class="container">
            <div class="title">选择授权方式</div>
            <div class="tips">根据您目前店铺的接单打印方式选择适合的授权方式</div>
            <div class="card" @click="onChange('1')">
                <div class="info">
                    <h2>美团商家版</h2>
                    <p>未绑定其他系统，使用美团外卖商家版接单和打印，选此项进行直接绑定同步订单。</p>
                </div>
                <van-icon name="arrow" color="#666" />
            </div>
            <div class="card" @click="onChange('1')">
                <div class="info">
                    <h2>无线云打印机</h2>
                    <p>已使用飞鹅、商鹏、易联、中午云等无线云打印机接单，选此项进行直接绑定同步订单。</p>
                </div>
                <van-icon name="arrow" color="#666" />
            </div>
            <div class="card" @click="onChange('0')">
                <div class="info">
                    <h2>其他接单系统</h2>
                    <p>已使用其他系统接单，如收银、运营、店铺管理等系统接单，选此项进行兼容绑定同步订单(无冲突)</p>
                    <p class="notice">注:用此方式绑定，美团系统不会同步顾客的催单及退款信息，请留意主接单系统对应信息</p>
                </div>
                <van-icon name="arrow" color="#666" />
            </div>
        </div>
    </van-popup>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const $emit = defineEmits(['close']);
const show = ref(true);

const onChange = (val)=>{
    $emit('close', val);
};

const onClose = ()=>{
    $emit('close');
};

</script>

<style lang="scss" scoped>
.container{
    padding: .3rem;
    padding-bottom: .8rem;
    .title{
        height: .6rem;
        line-height: .6rem;
        font-size: .32rem;
        line-height: .6rem;
        color: #000;
        text-align: center;
    }
    .tips{
        font-size: .3rem;
        line-height: 1.5;
        color: #333;
        padding: .2rem 0;
    }
    .card{
        width: 100%;
        padding: .3rem;
        box-sizing: border-box;
        background: #FFF;
        border-radius: .2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .2rem;
        .info{
            flex: 1;
            padding-right: .5rem;
        }
        h2{
            font-size: .32rem;
            color: #333;
            line-height: 1.5;
        }
        p{
            font-size: .24rem;
            color: #666;
            line-height: 1.5;
            padding: .1rem 0;
        }
        p.notice{
            font-size: .22rem;
        }
    }
}
</style>
<template>
	<div class="page">
		<div class="list">
			<div class="li" v-for="item in list" :key="item.id" @click="onChoose(item)">
				<div class="logo"><img :src="item.logo" alt=""></div>
				<div class="info">
					<div class="name">{{item.name}}</div>
				</div>
				<div class="status">
					<text>{{item.authorizationStatusValue}}</text>
				</div>
			</div>
		</div>


		<!-- 饿百零售弹窗 -->
		<eleRetail v-if="showEleRetail" @close="onEleRetailClose" @change="onEleRetailChange" />

		<eleStoreId v-if="showEleStoreId" @close="onEleStoreIdClose" />

		<!-- 美团外卖 -->
		<meituanwaimai v-if="showMeituanwaimai" @close="onMeituanwaimaiClose" />

	</div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { http } from "@/http";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import eleRetail from "./components/eleRetail.vue";
import eleStoreId from "./components/eleStoreId.vue";
import meituanwaimai from "./components/meituanwaimai.vue";

const $route = useRoute();
const $router = useRouter();

const query = $route.query || {};

const list = ref([]);
const showEleRetail = ref(false);
const showEleStoreId = ref(false);
const showMeituanwaimai = ref(false);

const curItem = ref({});
const bindType = ref('');



onMounted(()=>{
    login();
});

const login = ()=>{
    let params = {
        loginType: 'H5',
        sideType: 'H5',
        shopId: query.shopId
    };
    http.post('/?cmd=com.xqxc.api.pigear.biz.account.UserLoginService.userLogin&group=earApp', params).then(res=>{
        let _auth = res.result;
		window.localStorage.setItem('phkjPigearAuth', JSON.stringify(_auth));
		getList();
    }).catch(err=>{
        Toast(err.errorName);
    });
};

const getList = ()=>{
	let params = { id: query.pigearShopId};
	http.post("/?cmd=com.xqxc.api.pigear.biz.account.ShopInfoService.listShopOrderSource&group=earApp", params).then((res) => {
		list.value = res.result || [];
		let _curItem = null;
		res.result.forEach(v=>{
			if(v.code === query.code){
				_curItem = v;
			}
		});
		_curItem && onChoose(_curItem);
    }).catch((err) => {
        console.log(err, "err");
    });
};

const onChoose = (item)=>{
	curItem.value = item;
    if(item.authorizationStatus == 1){
        Toast('已授权，无需再次授权');
        return false;
    }

	// 饿了么外卖, 零售
	if(['ELE', 'ELE_RETAIL', 'MT'].includes(item.code) ){
		getAuthLink({
			orderSourceId: item.id,
			orderSourceCode: item.code,
			pigearShopId: item.shopId,
			pigearShopName: item.shopName,  // 猪耳朵店铺名称
			pigearLatitude: item.latitude,
			pigearLongitude: item.longitude
		}, (res)=>{
			window.location.href = res.authUrl;
		});
	}

	// 美团外卖 
	if (item.code == 'MTWM'){
		showMeituanwaimai.value = true;
	}


	// 聚单客_饿了么外卖
	if (item.code == 'JDK_ELE'){
		getJdkAuthLink({
			orderSourceId: item.id,
			orderSourceCode: item.code,
			pigearShopId: item.shopId,
			jdkRelativeUserId: item.jdkRelativeUserId,  // 聚客单商家id
			jdkShopId: item.jdkShopId,  // 聚客单shopId
			pigearShopName: item.shopName,  // 猪耳朵店铺名称
			bindType: '0',    // 绑定方式 美团支持兼容绑定 1 直接绑定 0 兼容绑定
			// cancelAuthCode: 0,  //只有解绑才传，美团外卖解绑授权 MTUNBIND
			//sourceShopId: 0;   // 饿了么零售店铺ID
		}, (res)=>{
			window.location.href = res.authUrl;
		});
	}
	
	// 饿了么零售， 需搜集ID 
	if (['JDK_ELE_RETAIL'].includes(item.code) ){
		showEleRetail.value = true;
	}
	
	// 聚单客_美团闪购 
	if (item.code == 'JDK_MTSG'){
		getJdkAuthLink({
			orderSourceId: item.id,
			orderSourceCode: item.code,
			pigearShopId: item.shopId,
			jdkRelativeUserId: item.jdkRelativeUserId,  // 聚客单商家id
			jdkShopId: item.jdkShopId,  // 聚客单shopId
			pigearShopName: item.shopName,  // 猪耳朵店铺名称
			bindType: '0',    // 绑定方式 美团支持兼容绑定 1 直接绑定 0 兼容绑定
		}, (res) => {
			window.location.href = res.authUrl;
		});
	}
	
	// 聚单客_美团外卖 
	if (item.code == 'JDK_MTWM'){
		showMeituanwaimai.value = true;
	}
};

const getAuthLink = (params, cb)=>{
	http.post("/elm/callback/getAuthUrl", params).then((res) => {
		cb && cb(res.result);
	}).catch((err) => {
		Toast(err.errorName);
	});
};

const getJdkAuthLink = (params, cb)=>{
	http.post("/elm/callback/getJdkAuthUrl", params).then((res) => {
		cb && cb(res.result);
	}).catch((err) => {
		Toast(err.errorName);
	});
};

// 饿了么零售绑定类型选择
const onEleRetailChange = (val)=>{
	onEleRetailClose();
	bindType.value = val;
	showEleStoreId.value = true;
};
const onEleRetailClose = ()=>{
	showEleRetail.value = false;
};

const onEleStoreIdClose = (val)=>{
	let sourceShopId = val ? val : undefined;
	let item = curItem.value;
	showEleStoreId.value = false;
	if(sourceShopId){
		getJdkAuthLink({
			orderSourceId: item.id,
			orderSourceCode: item.code,
			pigearShopId: item.shopId,
			jdkRelativeUserId: item.jdkRelativeUserId,  // 聚客单商家id
			jdkShopId: item.jdkShopId,  // 聚客单shopId
			pigearShopName: item.shopName,  // 猪耳朵店铺名称
			bindType: bindType.value,    // 绑定方式  1 直接绑定 0 兼容绑定
			sourceShopId: sourceShopId
		}, res=>{
			window.location.href = res.authUrl;
		});
	}
};

const onMeituanwaimaiClose = (val)=>{
	if(val){
		bindType.value = val;
		let item = curItem.value;
		if(item.code === 'MTWM'){
			getAuthLink({
				orderSourceId: item.id,
				orderSourceCode: item.code,
				pigearShopId: item.shopId,
				pigearShopName: item.shopName,  // 猪耳朵店铺名称
				businessId: val === '1' ? '2' : '16',
				pigearLatitude: item.latitude,
				pigearLongitude: item.longitude
			}, (res)=>{
				window.location.href = res.authUrl;
			});
			return false;
		}
		getJdkAuthLink({
			orderSourceId: item.id,
			orderSourceCode: item.code,
			pigearShopId: item.shopId,
			jdkRelativeUserId: item.jdkRelativeUserId,  // 聚客单商家id
			jdkShopId: item.jdkShopId,  // 聚客单shopId
			pigearShopName: item.shopName,  // 猪耳朵店铺名称
			bindType: bindType.value,    // 绑定方式  1 直接绑定 0 兼容绑定
		}, res => {
			window.location.href = res.authUrl;
		});
	}
	showMeituanwaimai.value = false;
};

</script>

<style lang="scss" scoped>
.page{
    background: #f3f7fa;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
	.list{
		background-color: #FFF;
		padding: 0 .3rem;
		.li{
			height: 1.4rem;
			border-bottom: 0.01rem solid #eee;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&:last-child{
				border-bottom: none;
			}
			.logo{
				width: 0.8rem;
				height: 0.8rem;
				border-radius: 50%;
				overflow: hidden;
				image{
					width: 0.8rem;
					height: 0.8rem;
				}
			}
			.info{
				flex: 1;
				padding: 0 0.2rem;
                line-height: 1.5;
				.name{
					font-size: 0.34rem;
					color: #000;
				}
				.desc{
					font-size: 0.28rem;
					color: #999;
				}
			}
			.status{
				display: flex;
				align-items: center;
				color: #999;
                background: url('../../assets/images/icon.arrow.right.png') no-repeat right center;
                background-size: 0.11rem 0.22rem;
                padding-right: 0.2rem;
			}
		}
	}
}
</style>
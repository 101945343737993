<template>
    <van-popup v-model:show="show" position="bottom" :style="{background: '#EEE'}" @close="onClose" closeable round>
        <div class="container">
            <div class="title">选择授权方式</div>
            <div class="tips">根据您目前店铺的接单打印方式选择适合的授权方式</div>
            <div class="card" @click="onBindType('1')">
                <div class="info">
                    <h2>饿百零售商家版</h2>
                    <p>未绑定其他系统，使用饿百零售商家版接单和打印小票，选此项进行直接绑定同步订单。</p>
                </div>
                <van-icon name="arrow" color="#666" />
            </div>
            <div class="card" @click="onBindType('0')">
                <div class="info">
                    <h2>其他接单系统</h2>
                    <p>已使用其他系统接单，如收银、运营、店铺管理等系统接单，选此项进行兼容绑定同步订单(无冲突)</p>
                    <p class="notice">注:用此方式绑定，饿百零售系统不会同步顾客的催单及退款信息，请留意主接单系统对应信息</p>
                </div>
                <van-icon name="arrow" color="#666" />
            </div>
        </div>
    </van-popup>

</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { useRoute, useRouter } from "vue-router";


const $emit = defineEmits(['close', 'change']);
const $route = useRoute();
const $router = useRouter();

const show = ref(true);


const onClose = ()=>{
    $emit('close');
};

const onBindType = (val)=>{
    $emit('change', val);
};
</script>

<style lang="scss" scoped>
.container{
    padding: .3rem;
    padding-bottom: .8rem;
    .title{
        height: .6rem;
        line-height: .6rem;
        font-size: .32rem;
        line-height: .6rem;
        color: #000;
        text-align: center;
    }
    .tips{
        font-size: .3rem;
        line-height: 1.5;
        color: #333;
        padding: .2rem 0;
    }
    .card{
        width: 100%;
        padding: .3rem;
        box-sizing: border-box;
        background: #FFF;
        border-radius: .2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .2rem;
        .info{
            flex: 1;
            padding-right: .5rem;
        }
        h2{
            font-size: .32rem;
            color: #333;
            line-height: 1.5;
        }
        p{
            font-size: .24rem;
            color: #666;
            line-height: 1.5;
            padding: .1rem 0;
        }
        p.notice{
            font-size: .22rem;
        }
    }
}
</style>
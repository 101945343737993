<template>
    <van-popup v-model:show="show" position="center" :style="{background: '#EEE'}" round @close="onClose">
        <div class="container">
            <div class="title">饿了么零售店铺ID</div>
            <div class="tips">请先登录饿了么零售后台-门店设置中取得门店ID，输入后点击"确定“进行绑定</div>
            <div class="form">
                <input class="input" v-model="storeId" type="text" placeholder="请输入饿了么零售店铺ID">
            </div>
            <div class="btns">
                <van-button type="default" @click="cancel">取 消</van-button>
                <van-button type="primary" @click="ensure">确 定</van-button>
            </div>
            <div class="help" @click="scanGuide">查看授权教程</div>
        </div>
    </van-popup>
</template>

<script setup>
import { Toast } from 'vant';
import { ref, defineEmits, defineProps } from 'vue';
import { useRoute, useRouter } from "vue-router";

const props = defineProps();
const $emit = defineEmits(['close']);
const $router = useRouter();

const show = ref(true);
const storeId = ref('');

const cancel = ()=>{
    onClose();
};

const ensure = ()=>{
    if(!storeId.value){
        Toast('请输入饿了么零售店铺ID');
        return false;
    }
    $emit('close', storeId.value);
};

const onClose = ()=>{
    $emit('close');
};

const scanGuide = ()=>{
    $router.push('/phkjAuth/eleRetailGuide');
};
</script>

<style lang="scss" scoped>
.container{
    padding: .3rem;
    width: 5.3rem;
    background: #FFF;
    .title{
        height: .6rem;
        line-height: .6rem;
        font-size: .32rem;
        line-height: .6rem;
        color: #000;
        text-align: center;
    }
    .tips{
        font-size: .3rem;
        line-height: 1.5;
        color: #333;
        padding: .2rem 0;
    }
    .form{
        padding: .2rem 0;
        .input{
            width: 100%;
            height: .8rem;
            line-height: .8rem;
            padding: 0 .3rem;
            box-sizing: border-box;
            border-radius: .04rem;
            border: 0.01rem solid #eee;
        }
    }
    .btns{
        width: 100%;
        height: 1rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        button{
            width: 1.6rem;
        }
    }
    .help{
        height: .4rem;
        line-height: .4rem;
        padding-top: .4rem;
        text-align: right;
        color: #4788e3;
    }
}
</style>